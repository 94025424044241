import logo from './logo.svg';
import './App.css';

import quotation_img from './images/quotation.png';
import grade_img from './images/gradepic.png';
import board_img from './images/board.png';
import d2_img from './images/d2pic.png';
import github_logo from './images/GitHubLogo.png';
import github_mark from './images/GitHubMark.png';
import { ReactComponent as Cd } from './images/cd.svg';
// import { ReactComponent as Html5 } from './images/html5-brands.svg';
// import { ReactComponent as Database } from './images/database-solid.svg';
// import { ReactComponent as Java } from './images/java-brands.svg';
// import { ReactComponent as Js } from './images/js-brands.svg';
// import { ReactComponent as ReactS } from './images/react-brands.svg';
import { ReactComponent as Burger } from './images/burger.svg';
import { ReactComponent as Phone } from './images/smartphone.svg';
import { ReactComponent as Mail } from './images/mail.svg';
import { useEffect, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Pdf from './resume.pdf';

function App() {
	const navBar = useRef(null);
	const headerBg = useRef(null);
	const csIcon = useRef(null);
	const burgerIcon = useRef(null);
	const [navVisible, setNavVisible] = useState(false);

	const onNavClick = () => {
		if (!navVisible) {
			navBar.current.className = "nav-visible";
			headerBg.current.setAttribute('class', "heading orange");
			csIcon.current.setAttribute('class', "svg-black");
			burgerIcon.current.setAttribute('class', "svg-black");
			setNavVisible(true);
		} else {
			navBar.current.className = "nav-hidden";
			headerBg.current.setAttribute('class', "heading");
			csIcon.current.setAttribute('class', "svg-orange");
			burgerIcon.current.setAttribute('class', "svg-orange");
			setNavVisible(false);
		}
	}

	return (
		<>
			<div className="landing">
				<header>
					<div className="heading" ref={headerBg}>
						<Cd id="cd-top" className="svg-orange" ref={csIcon} />
						<Burger id="clicker" onClick={onNavClick} className="svg-orange" ref={burgerIcon} />
						<nav id="nav-big">
							<ul>
								<li><a href={Pdf} target="_blank">Resume</a></li>
								<li><a href="https://github.com/NoodlesCD" target="_blank">GitHub</a></li>
								
							</ul>
						</nav>
					</div>
					<nav id="nav-bar" ref={navBar}>
						<ul>
							<li><a href={Pdf} target="_blank">Resume</a></li>
							<li><a href="https://github.com/NoodlesCD" target="_blank">GitHub</a></li>
							
						</ul>
					</nav>
					<a href="#" className="btn btn-primary" style={{ display: "none" }}>Contact Me!</a>
				</header>

				<section className="intro">
					<header className="intro-header">
						<h1><span className="hi">Hi</span><span className="imchris">, I'm Chris</span></h1>
					</header>
					<p>
						I'm a recently graduated software development student with a passion for technology. Looking to continue my growth and learning as I pursue my career.
          			</p>
					<div className="button-group">
						<a href="mailto:csdurnan@gmail.com" className="btn btn-primary">Get in touch</a>
						<a href={Pdf} target="_blank" className="btn btn-secondary">View my resume</a>
					</div>
				</section>
			</div>

			<section className="about">
				<Fade bottom>
				<header className="about-header">
					<h2>About Me</h2>
					<div className="decoration-box">
						<div className="decor-top"></div>
						<div className="decor-bottom"></div>
					</div>
				</header>
				<div className="about-body">
					<p>
						I am a recent graduate from SAIT's Software Development program, currently residing in Calgary, Alberta.
          			</p>
					<p>
						Computers and technology are my passion and I thoroughly enjoy learning and engaging in multiple aspects, from backend programming, to web development to building my own computers.
          			</p>
					<p>
						Feel free to look over some of my work below or on my GitHub page. Or contact me via email regarding possible opportunities.
          			</p>
				</div>
				</Fade>
				<Fade bottom>
				<div className="about-form">
					<form action="https://getform.io/f/d0a681ca-067d-41d2-8a92-7edf31a71154" method="POST" >
						<div className="form-upper">
							<div className="form-box">
								<label for="name">Your name</label> <br />
								<input type="text" id="name" name="name" />
							</div>
							<div className="form-box">
								<label for="email">Your email</label> <br />
								<input type="text" id="email" name="email" />
							</div>
						</div>
						<div className="form-lower">
							<label for="message">Message</label> <br />
							<textarea id="message" rows="4" name="message"/>
						</div>
						<div className="form-button-area">
							<button type="submit" className="btn btn-tri">Send</button>
						</div>
						<div className="form-contact-info">
							<div className="form-contact">
								<Mail height="50px" width="50px" /> csdurnan@gmail.com
							</div>
							<div className="form-contact">
								<Phone height="50px" width="50px" /> 403-870-6299
							</div>
						</div>
					</form>
				</div>
				</Fade>
			</section>

			<section className="work">
				<header className="work-header">
					<h2>My Work</h2>
					<div className="decoration-box-work">
						<div className="decor-top-work"></div>
						<div className="decor-bottom-work"></div>
					</div>
				</header>

				<Fade bottom>
				<div className="work-card">
					<div className="work-heading-bg">

					</div>
					<header className="work-heading">
						<h3>Quotation System</h3>
					</header>
					<div className="work-img">
						<img src={quotation_img} />
					</div>
					<div className="work-body">
						<p>
							A web-based application built using Java and ReactJS. Built for the Capstone Project course at SAIT, allowing customers to request a quote on products and the ability for employees to respond to the quote and set custom pricing for their customers.
            </p>
					</div>
					<div className="work-git">
					</div>
					<div className="work-components">
						<ul>
							<li>ReactJS</li>
							<li>MariaDB</li>
							<li>Java</li>
							<li>Spring</li>
						</ul>
					</div>
				</div>
				</Fade>

				<Fade bottom>
				<div className="work-card">
					<div className="work-heading-bg">

					</div>
					<header className="work-heading">
						<h3>Destiny Discord Bot</h3>
					</header>
					<div className="work-img">
						<img src={d2_img} />
					</div>
					<div className="work-body">
						<p>
							A Discord bot built using Javascript that connects to the API for the game Destiny 2. Returns information, statistics and possible perk combinations for the weapon requested by the user.
            </p>
					</div>
					<div className="work-components">
						<ul>
							<li>NodeJS</li>
							<li>API</li>
						</ul>
					</div>
					<div className="work-git">
						<a href="https://github.com/NoodlesCD/D2WeaponsDiscordBot" target="_blank">View on GitHub ⮞</a>
					</div>
				</div>
				</Fade>

				<Fade bottom>
				<div className="work-card">
					<div className="work-heading-bg">

					</div>
					<header className="work-heading">
						<h3>Grade Calculator</h3>
					</header>
					<div className="work-img">
						<img src={grade_img} />
					</div>
					<div className="work-body">
						<p>
							A Python script created during my time at SAIT. Retrieves information off of SAIT's grading page and calculates a current grade in the class based off grade received and its weighting.
            </p>
					</div>
					<div className="work-components">
						<ul>
							<li>Python</li>
						</ul>
					</div>
					<div className="work-git">
						<a href="https://github.com/NoodlesCD/GradeCalculator" target="_blank">View on GitHub ⮞</a>
					</div>
				</div>
				</Fade>

				<Fade bottom>
				<div className="work-card">
					<div className="work-heading-bg">

					</div>
					<header className="work-heading">
						<h3>Message Board</h3>
					</header>
					<div className="work-img">
						<img src={board_img} />
					</div>
					<div className="work-body">
						<p>
							A work-in-progress message board utilizing ReactJS for a frontend UI alongside Java and MariaDB to provide a REST API for data retrieval and storage. Allows users to start and join in discussions they find interesting.
            </p>
					</div>
					<div className="work-git">
					</div>
					<div className="work-components">
						<ul>
							<li>ReactJS</li>
							<li>MariaDB</li>
							<li>Java</li>
							<li>Spring</li>
						</ul>
					</div>
				</div>
				</Fade>
			</section>

			<Fade bottom>
			<section className="blog">
				<heading className="blog-heading">
					<h2>Blog Posts</h2>
					<div className="decoration-box-blog">
						<div className="decor-top"></div>
						<div className="decor-bottom"></div>
					</div>
				</heading>

				<div className="blog-card">
					<header className="blog-card-title">
						<h3>REST API with Springboot - Part II</h3>
					</header>
					<div className="blog-body">
						<p>
							For part two we will go into greater detail about how to create a web application using Springboot.
							Utilizing the framework we will create a shopping cart application using Springboot and MariaDB.</p>
					</div>
					<div className="blog-link">
						<a href="https://github.com/NoodlesCD/noodlescd.github.io/blob/main/blogpost2.md" target="_blank">View post ⮞</a>
					</div>
				</div>

				<div className="blog-card">
					<header className="blog-card-title">
						<h3>REST API with Springboot</h3>
					</header>
					<div className="blog-body">
						<p>
							In this post we discuss some of the benefits of Spring and Springboot and what they can bring to a Java project.
							Also a sneak peak into the beginnings of creating a web application using the framework.</p>
					</div>
					<div className="blog-link">
						<a href="https://github.com/NoodlesCD/noodlescd.github.io/blob/main/blogpost1.md" target="_blank">View post ⮞</a>
					</div>
				</div>
			</section>
			</Fade>
			<footer>
				<div className="footer-left">
					<Cd />
				</div>
				<div className="footer-right">
					<ul>
						<li><Mail height="50px" width="50px" /> <a href="mailto:csdurnan@gmail.com">csdurnan@gmail.com</a></li>
						<li><Phone height="50px" width="50px" /> <a href="tel:1-403-870-6299">403-870-6299</a></li>
					</ul>
				</div>
			</footer>

		</>
	);
}

export default App;
